import { DIPOBackBaseResponse, MessageResponse } from '@model/response/@base-response/dipo-back-base-response';
import { from, map, Observable } from 'rxjs';
import { axios } from '@core/axios';
import { ApplicationStatus } from '@model/application-status';
import { ApplicationMenuItem } from '@model/application-menu-item';
import { ApplicationMetaResponse } from '@model/response/application-meta.response';
import { SecurityType } from '@model/enum/security-type.enum';
import { ApplicationTab } from '@containers/application/types/application-tab';
import { ApplicationFormStatus } from '@containers/application/models/application-form-status';
import { CompanyProfileResponse } from '@model/response/company-profile.response';
import { SubmissionMethodResponse } from '@model/response/submission-method.response';
import { SecurityProfileResponse } from '@containers/application/models/response/security-profile';
import { SecurityProfileRequest } from '@containers/application/models/request/security-profile';
import { CompanyProfileRequest } from '@model/request/company-profile';
import { AuthorizedProfileResponse } from '@containers/application/models/response/authorized-profile';
import { DigitalSignManagementResponse as DigitalSignManagementResponse } from '@containers/application/models/response/digital-sign-management';
import { AuthorizedProfileRequest } from '@containers/application/models/request/authorized-profile';
import { RequestDigitalSignRequest } from '@containers/application/models/request/request-digital-sign';
import { v4 as uuid } from 'uuid';
import { DraftProfileResponse } from '@model/response/draft-profile.response';
import { InformationMemorandumResponse } from '@model/response/information-memorandum.response';

export class ApplicationService {
  static basePath = '/application';

  public static getStatusApplicationByReservedSymbolId(reservedSymbolId: number): Observable<ApplicationStatus> {
    const url = `${this.basePath}/${reservedSymbolId}/status`;
    return from(axios.get<DIPOBackBaseResponse<ApplicationStatus>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static getMenus(symbolId: number): Observable<ApplicationMenuItem[]> {
    const url = `${this.basePath}/${symbolId}/menu`;
    return from(axios.get<DIPOBackBaseResponse<ApplicationMenuItem>>(url)).pipe(map(({ data }) => data.data));
  }

  public static getMeta(applicationId: number): Observable<ApplicationMetaResponse> {
    const url = `${this.basePath}/${applicationId}/meta`;
    return from(axios.get<DIPOBackBaseResponse<ApplicationMetaResponse>>(url)).pipe(
      map(({ data }) => data.data[0]),
      map((data) => ({ ...data, typeOfSecurities: data.typeOfSecurities.toLowerCase() as SecurityType }))
    );
  }

  public static getCompanyProfile(applicationId: number): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile`;
    return from(axios.get<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateCompanyProfile(
    applicationId: number,
    data: CompanyProfileRequest
  ): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static requestUnlockCompanyProfile(applicationId: number): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/request-unlock`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateCompanyProfileDraft(
    applicationId: number,
    data: CompanyProfileRequest
  ): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/draft`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static withdrawDraftCompanyProfile(applicationId: number): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/draft/withdraw`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static sendToApproveDraftCompanyProfile(
    applicationId: number,
    editReason?: string
  ): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/draft/send-to-approve`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url, { editReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static rejectDraftCompanyProfile(
    applicationId: number,
    rejectReason?: string
  ): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/draft/reject`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url, { rejectReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static approveDraftCompanyProfile(applicationId: number): Observable<DraftProfileResponse<CompanyProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/company-profile/draft/approve`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<CompanyProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static checkComplete(applicationId: number, key: ApplicationTab): Observable<ApplicationFormStatus> {
    const url = `${this.basePath}/${applicationId}/check-complete`;
    return from(axios.get<DIPOBackBaseResponse<ApplicationFormStatus>>(url, { params: { key } })).pipe(map(({ data }) => data.data[0]));
  }

  public static getSubmissionMethod(applicationId: number): Observable<SubmissionMethodResponse> {
    const url = `${this.basePath}/${applicationId}/submission-method`;
    return from(axios.get<DIPOBackBaseResponse<SubmissionMethodResponse>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateSubmissionMethod(applicationId: number, separateFiling: boolean | null): Observable<SubmissionMethodResponse> {
    const url = `${this.basePath}/${applicationId}/submission-method`;
    return from(axios.put<DIPOBackBaseResponse<SubmissionMethodResponse>>(url, { separateFiling })).pipe(map(({ data }) => data.data[0]));
  }

  public static getSecurityProfile(applicationId: number): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile`;
    return from(axios.get<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateSecurityProfile(
    applicationId: number,
    data: SecurityProfileRequest
  ): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static requestUnlockSecurityProfile(applicationId: number): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/request-unlock`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateSecurityProfileDraft(
    applicationId: number,
    data: SecurityProfileRequest
  ): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/draft`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static withdrawDraftSecurityProfile(applicationId: number): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/draft/withdraw`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static sendToApproveDraftSecurityProfile(
    applicationId: number,
    editReason?: string
  ): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/draft/send-to-approve`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url, { editReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static rejectDraftSecurityProfile(
    applicationId: number,
    rejectReason?: string
  ): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/draft/reject`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url, { rejectReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static approveDraftSecurityProfile(applicationId: number): Observable<DraftProfileResponse<SecurityProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/security-profile/draft/approve`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<SecurityProfileResponse>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static getAuthorizedProfile(applicationId: number): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile`;
    return from(axios.get<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static updateAuthorizedProfile(
    applicationId: number,
    data: AuthorizedProfileRequest
  ): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static requestUnlockAuthorizedProfile(applicationId: number): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/request-unlock`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static updateAuthorizedProfileDraft(
    applicationId: number,
    data: AuthorizedProfileRequest
  ): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/draft`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url, data)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static withdrawDraftAuthorizedProfile(applicationId: number): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/draft/withdraw`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static sendToApproveDraftAuthorizedProfile(
    applicationId: number,
    editReason?: string
  ): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/draft/send-to-approve`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url, { editReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static rejectDraftAuthorizedProfile(
    applicationId: number,
    rejectReason?: string
  ): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/draft/reject`;
    return from(axios.post<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url, { rejectReason })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static approveDraftAuthorizedProfile(applicationId: number): Observable<DraftProfileResponse<AuthorizedProfileResponse>> {
    const url = `${this.basePath}/${applicationId}/authorized-profile/draft/approve`;
    return from(axios.put<DIPOBackBaseResponse<DraftProfileResponse<AuthorizedProfileResponse>>>(url)).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static getDigitalSignManagement(applicationId: number): Observable<DigitalSignManagementResponse> {
    const url = `${this.basePath}/${applicationId}/digital-sign-management`;
    return from(axios.get<DIPOBackBaseResponse<DigitalSignManagementResponse>>(url)).pipe(
      map(({ data }) => data.data[0]),
      map((data) => {
        return {
          faAuthorized: (data.faAuthorized || []).map((d) => ({ ...d, id: uuid() })),
          issuerJuristics: (data.issuerJuristics || []).map((d) => ({ ...d, id: uuid() })),
          authorizedGroup: (data.authorizedGroup || []).map((d) => ({ ...d, id: uuid() })),
          lock: data.lock,
        };
      })
    );
  }

  public static requestDigitalSign(applicationId: number, data: RequestDigitalSignRequest): Observable<MessageResponse> {
    const url = `${this.basePath}/${applicationId}/request-digital-sign`;
    return from(axios.patch<MessageResponse>(url, data)).pipe(map(({ data }) => data));
  }

  public static getInformationMemorandum(applicationId: number): Observable<InformationMemorandumResponse> {
    const url = `${this.basePath}/${applicationId}/im`;
    return from(axios.get<DIPOBackBaseResponse<InformationMemorandumResponse>>(url)).pipe(map(({ data }) => data.data[0]));
  }
}
