export enum ApplicationSymbolMenuKeys {
  // Symbol menu
  RESERVE_SYMBOL = 'RESERVE_SYMBOL',
  STAKEHOLDER = 'STAKEHOLDER',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  AUDITOR = 'AUDITOR',
  UNDERWRITER = 'UNDERWRITER',
  SEC = 'SEC',
  SET = 'SET',
  TIMELINE = 'TIMELINE',

  // Application menu
  CREATE_FORM_AND_FILING = 'CREATE_FORM_AND_FILING',
  PREPARE_INFO = 'PREPARE_INFO',
  COMPANY_INFO = 'COMPANY_INFO',
  STOCK_INFO = 'STOCK_INFO',
  AUTHORIZED_PERSON_INFO = 'AUTHORIZED_PERSON_INFO',
  DIGITAL_SIGN_MANAGEMENT = 'DIGITAL_SIGN_MANAGEMENT',
  SUBMISSION_METHOD = 'SUBMISSION_METHOD',
  PRE_APPROVE_DOCUMENT = 'PRE_APPROVE_DOCUMENT',
  PREPARE_FORM_AND_FILING = 'PREPARE_FORM_AND_FILING',
  PREPARE_FORM = 'PREPARE_FORM',
  DOCUMENT = 'DOCUMENT',
  PREPARE_FILING = 'PREPARE_FILING',
  FILING = 'FILING',
  FSCOMP = 'FSCOMP',
  SUBMIT_FORM_AND_FILING = 'SUBMIT_FORM_AND_FILING',
  SALES_REPORT = 'SALES_REPORT',
  FORM_81 = 'FORM_81',
  MAJOR_SHARE_HOLDER = 'MAJOR_SHARE_HOLDER',
  INFORMATION_MEMORANDUM = 'INFORMATION_MEMORANDUM',

  // SEC SET Consider process
  SEC_CONSIDERED_PROCESS = 'SEC_CONSIDERED_PROCESS',
  SET_CONSIDERED_PROCESS = 'SET_CONSIDERED_PROCESS',
}

export enum DashboardMenuKeys {
  // Dashboard menu
  HOME = 'HOME',
  DETAIL_OF_COMPANY = 'DETAIL_OF_COMPANY',
  COMPANY_INFO = 'COMPANY_INFO',
  AUTHORIZED_SIGNATURE = 'AUTHORIZED_SIGNATURE',
  DOCUMENT_USER_LIST = 'DOCUMENT_USER_LIST',
  USER_INFO = 'USER_INFO',
  APPROVE_USER = 'APPROVE_USER',
  ADD_USER = 'ADD_USER',
  ADD_USER_SEC = 'ADD_USER_SEC',
  ADD_USER_SET = 'ADD_USER_SET',
  USER_LIST = 'USER_LIST',
  USER_LIST_SEC = 'USER_LIST_SEC',
  USER_LIST_SET = 'USER_LIST_SET',
  REPORTS_AND_STATISTICS = 'REPORTS_AND_STATISTICS',
  EQUITY_INSTRUMENTS_INFO = 'EQUITY_INSTRUMENTS_INFO',
  CORPORATE_CONTACT_INFO = 'CORPORATE_CONTACT_INFO',
  AUDITOR = 'AUDITOR',
  MANAGEMENT = 'MANAGEMENT',
  RESERVE_SPECIAL_SYMBOL = 'RESERVE_SPECIAL_SYMBOL',
  COMMON_SYMBOL_LIST = 'COMMON_SYMBOL_LIST',
  TRUSTEE = 'TRUSTEE',
  COMPANY = 'COMPANY',
  TEMPLATE_OFFERING_DOCUMENT_AND_PO = 'TEMPLATE_OFFERING_DOCUMENT_AND_PO',
  TEMPLATE_FILING = 'TEMPLATE_FILING',
  CONTACT_MANAGEMENT = 'CONTACT_MANAGEMENT',
  USER_MANUAL_AND_RELATED_DOCUMENTS = 'USER_MANUAL_AND_RELATED_DOCUMENTS',
  CHECK_SYMBOL_NAME = 'CHECK_SYMBOL_NAME',
}
