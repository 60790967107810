import React from 'react';
import { Input } from '@mis/sushi-tailwind-react';
import { TextDisplay } from '@components/text-display/TextDisplay';
import { replaceAllowCharacter } from '@helpers/@utils/replace-allow-characters';
import { v4 as uuid } from 'uuid';

export type InputStringProps = Omit<React.ComponentProps<typeof Input>, 'status'> & {
  allowOnly?: 'en' | 'th';
  status?: React.ComponentProps<typeof Input>['status'];
};

const InputString: React.FC<InputStringProps> = ({ allowOnly, onChange, ...props }) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!allowOnly || !e.target.value) return onChange?.(e);
    const event = replaceAllowCharacter(e, allowOnly);
    onChange?.(event);
  };

  if (props.viewOnly) {
    return <TextDisplay name={props.id || uuid()} value={props.value} />;
  }

  return (
    <Input
      data-testid={props.id}
      autoComplete="off"
      {...props}
      style={{
        color: 'black',
        ...props.style,
      }}
      value={props.value === null ? '' : props.value}
      onChange={onChangeValue}
    />
  );
};

export default InputString;
